@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?pcbse6');
  src:  url('../fonts/icomoon.eot?pcbse6#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?pcbse6') format('truetype'),
    url('../fonts/icomoon.woff?pcbse6') format('woff'),
    url('../fonts/icomoon.svg?pcbse6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none; 
  line-height: 1;
 
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\e914";
}
.icon-vimeo:before {
  content: "\e915";
}
.icon-pdf:before {
  content: "\e913";
}
.icon-size-guide:before {
  content: "\e912";
}
.icon-phone-thin:before {
  content: "\e911";
}
.icon-polygon-fill:before {
  content: "\e910";
}
.icon-gloves:before {
  content: "\e90f";
}
.icon-compare:before {
  content: "\e90e";
}
.icon-close:before {
  content: "\e90d";
}
.icon-polygon:before {
  content: "\e90c";
}
.icon-mail:before {
  content: "\e90a";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-whatsapp:before {
  content: "\e90b";
}
.icon-phone:before {
  content: "\e909";
}
.icon-download:before {
  content: "\e908";
}
.icon-caret-up:before {
  content: "\e905";
}
.icon-caret-down:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-linkedin:before {
  content: "\e904";
}
.icon-twitter:before {
  content: "\e906";
}
.icon-youtube:before {
  content: "\e907";
}





// custom icons 


.icon-search{
  background-image: url('../img/icons/search.svg');
  width: 24px;
  height: 24px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
